/*
  Stolen from Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
  --blue: #3777ff;
  --dark: #181a21;
  /* --red: hsla(0, 80%, 70%, 1);
   */
  --red: hsla(6, 59%, 50%, 1);
  /* --gold: hsla(52, 80%, 70%, 1); */
  /* --gold: #c2b043; */
  --gold: hsla(47, 65%, 45%, 1);
  /* --green: hsla(103, 80%, 70%, 1); */
  /* --green: hsla(113, 65%, 45%, 1); */
  --green: hsla(128, 59%, 40%, 1);
  /* --blue: hsla(166, 80%, 70%, 1); */
  /* --purple: hsla(256, 80%, 70%, 1); */
  --purple: hsla(279, 59%, 50%, 1);
  /* --orange: hsla(24, 80%, 70%, 1); */
  --orange: hsla(28, 59%, 50%, 1);

  background-color: #ebeaea;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: "Lato", sans-serif;
  color: var(--dark);
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

@font-face {
  font-family: "Sunset Demi";
  src: url("/static/fonts/Sunset-Demi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Regular Pro";
  src:
    url("/static/fonts/apercu-regular-pro.woff") format("woff"),
    url("/static/fonts/apercu-regular-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Italic Pro";
  src:
    url("/static/fonts/apercu-italic-pro.woff") format("woff"),
    url("/static/fonts/apercu-italic-pro.woff2") format("woff2"),
    url("/static/fonts/apercu-italic-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
