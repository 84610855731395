*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  --blue: #3777ff;
  --dark: #181a21;
  --red: #cb4334;
  --gold: #bd9d28;
  --green: #2aa23a;
  --purple: #9634cb;
  --orange: #cb7a34;
  background-color: #ebeaea;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  color: var(--dark);
  font-family: Lato, sans-serif;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

@font-face {
  font-family: Sunset Demi;
  src: url("Sunset-Demi.0dd08f67.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Apercu Regular Pro;
  src: url("apercu-regular-pro.45fbf9bf.woff") format("woff"), url("apercu-regular-pro.3d685b81.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Apercu Italic Pro;
  src: url("apercu-italic-pro.1df51288.woff") format("woff"), url("apercu-italic-pro.685863a6.woff2") format("woff2"), url("apercu-italic-pro.3d8812f7.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*# sourceMappingURL=index.80c8cd88.css.map */
